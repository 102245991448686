import React, { useEffect, useMemo, useRef, useState, useCallback } from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { ArrowLeft } from "@bw/icons"
import { Schedule, Teaser } from "@bw/partials"
import { graphql } from "gatsby"
import AutoComplete from "react-google-autocomplete"
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api"
import { FormField } from "@bw/forms"
import { Teasers } from "@bw/modules"
import IconPin from "../images/pin.svg"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Seo, Button, Section } from "@bw/bits"
import { toast } from "react-toastify"
import mapStyle from "../utils/mapStyle"

const libraries = ["places"]

const Back = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
`

const MapWrap = styled.div`
  display: grid;
  grid-template-rows: 280px 1fr;
  height: 100vh;

  @media (min-width: 560px) {
    grid-template-rows: 480px 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 320px 1fr;
    grid-template-rows: unset;
  }
`

const Aside = styled.aside`
  position: relative;
  background-color: #fff;
  overflow-y: auto;

  @media (min-width: 1024px) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: 100%;
  }
`

const containerStyle = {
  width: "100%",
  height: "100%",
}

const ServiceGrid = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-gap: 10px;
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60px;
  width: 80px;
  font-size: 10px;
  line-height: 1;
  border-radius: 8px;
  position: relative;
  background-color: ${props => (props.active ? "#0070ba" : "#f0f0f0")};
  color: ${props => (props.active ? "#fff" : "initial")};
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  @media (min-width: 1024px) {
    width: 80px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    appearance: none;
    visibility: hidden;
  }

  span {
    position: relative;
    display: block;
    margin-top: 5px;
  }

  img {
    height: 24px;
    width: auto;
  }
`

const MainDrawer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 10px 30px;
`

const StationDrawer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  padding: 10px 30px;
  background-color: #fff;
  transform: translateX(${props => (props.open ? 0 : "-100%")});
  transition: transform 0.4s;

  @media (min-width: 560px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    > :first-child {
      grid-column: 2/3;
    }

    > :nth-child(2) {
      grid-row: 1/2;
    }
  }

  @media (min-width: 1024px) {
    display: block;
  }
`

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60px;
  width: 80px;
  font-size: 10px;
  line-height: 1;
  background-color: #f0f0f0;
  border-radius: 8px;

  img {
    height: 24px;
    width: auto;
    margin-bottom: 5px;
  }
`

const MobileTeaserWrapper = styled.div`
  overflow-y: auto;
`

const FilterButton = ({ active, label, icon, value, onChange }) => {
  return (
    <Label active={active}>
      {icon && icon}
      <input
        type="checkbox"
        checked={active}
        value={value}
        onChange={onChange}
      />
      <span>{label}</span>
    </Label>
  )
}

function isDST(d) {
  let december = new Date(d.getFullYear(), 12, 21).getTimezoneOffset()
  let june = new Date(d.getFullYear(), 6, 21).getTimezoneOffset()
  return Math.max(december, june) !== d.getTimezoneOffset()
}

const initialValues = {
  position: {
    lat: 46.8077153,
    lng: 8.5,
  },
  zoom: 8,
}

const MapPage = ({ data, pageContext, location }) => {
  const [online, setOnline] = useState(true)
  const urlParams = new URLSearchParams(location.search)
  const { t } = useTranslation()
  const mapRef = useRef(null)
  const autoCompleteRef = useRef(null)
  const [mapState, setMapState] = useState(initialValues)
  const [clustererInstance, setClustererInstance] = useState(null)
  const [clickedStation, setClickedStation] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState(
    urlParams.get("services")?.split(",") || []
  )

  data.allService.nodes.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )

  /**
   * If we received state from the stationsApp module
   * then initialize the map on the point
   */
  useEffect(() => {
    if (location?.state?.position) {
      setMapState(state => ({
        ...state,
        zoom: 12,
        position: location.state.position,
      }))
      if (autoCompleteRef.current) {
        autoCompleteRef.current.value =
          location.state?.position?.formatted_address
      }
    }
  }, [location?.state?.position])

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    libraries,
  })

  const schedule = isDST(new Date())
    ? clickedStation?.schedule?.summer
    : clickedStation?.schedule?.winter

  const stations = useMemo(() => {
    return data.allStation.nodes.map(station => ({
      ...station,
      availableServices: station.services.map(service => service.slug),
    }))
  }, [data.allStation])

  React.useEffect(() => {
    setOnline(navigator.onLine)

    window.addEventListener("online", () => {
      setOnline(true)
    })
    window.addEventListener("offline", () => {
      setOnline(false)
    })
  }, [])

  const stationsToDisplay = useMemo(() => {
    const results = stations.map(station => {
      let visible = true

      selectedFilters.forEach(filter => {
        if (!station.availableServices.includes(filter)) {
          visible = false
        }
      })

      return {
        ...station,
        visible,
      }
    })

    const visible = results.filter(station => station.visible)

    if (visible.length === 0) {
      toast.error(t("storeLocator.noResults"), {
        toastId: "noresults",
        autoClose: 2000,
      })
    }

    setTimeout(() => {
      if (online && clustererInstance) {
        clustererInstance.repaint()
      }
      setClickedStation(null)
    }, 100)

    return results
  }, [stations, selectedFilters, t, clustererInstance, online])

  /**
   * Handle map moves
   */
  useEffect(() => {
    if (isLoaded && mapRef.current) {
      mapRef.current.setCenter(mapState.position)
      mapRef.current.setZoom(mapState.zoom)
      setTimeout(() => {
        if (online && clustererInstance) {
          clustererInstance.repaint()
        }
        setClickedStation(null)
      }, 800)
    }
  }, [mapState, isLoaded, mapRef, clustererInstance, online])

  const handleResetClick = useCallback(() => {
    setSelectedFilters([])
    setMapState(initialValues)
    autoCompleteRef.current.value = null
  }, [])

  const onLoad = useCallback(
    mapInstance => {
      mapRef.current = mapInstance
      if (!location.state?.position) {
        const bounds = new window.google.maps.LatLngBounds()
        stations.forEach(station => {
          bounds.extend(new window.google.maps.LatLng(station.lat, station.lng))
        })
        mapInstance.fitBounds(bounds)
      }
    },
    [stations, location]
  )

  return (
    <MapWrap>
      <Seo
        title={t("storeLocator.metaTitle")}
        description={t("storeLocator.metaDescription")}
        meta={[
          {
            name: "robots",
            content: "max-image-preview:large, index, follow",
          },
        ]}
      />
      {isLoaded && online && (
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={containerStyle}
          clickableIcons={false}
          zoom={mapState.zoom}
          center={mapState.position}
          options={{
            maxZoom: 18,
            styles: mapStyle,
            disableDefaultUI: true,
          }}
        >
          <MarkerClusterer
            options={{
              imagePath:
                "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
            }}
            ignoreHidden={true}
            onLoad={markerClusterer => {
              setClustererInstance(markerClusterer)
            }}
          >
            {clusterer =>
              stationsToDisplay.map(station => (
                <Marker
                  key={station.id}
                  onClick={e => {
                    station.services.sort((a, b) =>
                      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    )
                    setClickedStation(station)
                    mapRef.current.panTo(e.latLng)
                  }}
                  icon={{
                    url: IconPin,
                  }}
                  position={{
                    lat: station.lat,
                    lng: station.lng,
                  }}
                  title={station.name}
                  clusterer={clusterer}
                  visible={station.visible}
                />
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
      )}
      {online === false && (
        <MobileTeaserWrapper>
          <Section>
            <Teasers>
              {stationsToDisplay
                .filter(s => s.visible)
                .map((station, i) => (
                  <Teaser
                    key={i}
                    title={station.name}
                    suptitle={`${station.zip} ${station.city.name}`}
                    to={station.slug}
                  />
                ))}
            </Teasers>
          </Section>
        </MobileTeaserWrapper>
      )}
      <Aside>
        <MainDrawer>
          <Back to="/">
            <ArrowLeft fillColor="currentColor" />
            <span>{t("storeLocator.back")}</span>
          </Back>
          <h1>{t("storeLocator.title")}</h1>
          <p>
            <Button
              small
              label={t("storeLocator.reset")}
              onClick={handleResetClick}
            />
          </p>
          {isLoaded && (
            <FormField title={t("storeLocator.search")}>
              <AutoComplete
                ref={autoCompleteRef}
                className="search-input"
                placeholder={t("storeLocator.searchPlaceholder")}
                options={{
                  types: ["(regions)"],
                  componentRestrictions: { country: "ch" },
                }}
                onPlaceSelected={({ geometry }) => {
                  if (!geometry) {
                    toast.error("pleaseChooseLocation")
                    return
                  }
                  setMapState(state => ({
                    ...state,
                    zoom: 12,
                    position: {
                      lat: geometry.location.lat(),
                      lng: geometry.location.lng(),
                    },
                  }))
                }}
              />
            </FormField>
          )}
          <FormField title={t("storeLocator.filterByService")}>
            <ServiceGrid>
              {data.allService.nodes.map(service => (
                <FilterButton
                  key={service.id}
                  active={selectedFilters.includes(service.slug)}
                  icon={
                    <img
                      src={
                        selectedFilters.includes(service.slug)
                          ? service.icon
                          : service.icon_select
                      }
                      alt=""
                    />
                  }
                  label={service.name}
                  value={service.slug}
                  onChange={event => {
                    const newValue = event.target.value
                    const index = selectedFilters.findIndex(
                      value => value === newValue
                    )
                    if (index === -1) {
                      setSelectedFilters([...selectedFilters, newValue])
                    } else {
                      setSelectedFilters([
                        ...selectedFilters.slice(0, index),
                        ...selectedFilters.slice(index + 1),
                      ])
                    }
                  }}
                />
              ))}
            </ServiceGrid>
          </FormField>
        </MainDrawer>
        <StationDrawer open={clickedStation !== null}>
          <div>
            <Button
              fill
              primary
              label={t("menu.close")}
              icon={
                <svg viewBox="0 0 24 24" height="18" width="18">
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    d="m3 3 18 18M3 21 21 3"
                  />
                </svg>
              }
              onClick={() => {
                setClickedStation(null)
              }}
            />
          </div>
          {clickedStation && (
            <div>
              <h4>
                {clickedStation.name}

                <br />
                <small>
                  <Link to={clickedStation.slug}>
                    {t("storeLocator.permalink")}
                  </Link>
                </small>
              </h4>
              <h5>{t("storeLocator.address")}</h5>
              <p>
                {clickedStation.street}
                <br />
                {clickedStation.zip} {clickedStation.city.name},{" "}
                {clickedStation.county}
              </p>
              {(clickedStation.phone_1 || clickedStation.phone_2) && (
                <>
                  <h5>{t("storeLocator.phone")}</h5>
                  {clickedStation.phone_1 && (
                    <div>
                      <a
                        href={`tel:${clickedStation.phone_1.replace(/ /g, "")}`}
                      >
                        {clickedStation.phone_1}
                      </a>
                    </div>
                  )}
                  {clickedStation.phone_2 && (
                    <div>
                      <a
                        href={`tel:${clickedStation.phone_2.replace(/ /g, "")}`}
                      >
                        {clickedStation.phone_2}
                      </a>
                    </div>
                  )}
                </>
              )}
              <Schedule schedule={schedule} />
              <h5>{t("storeLocator.services")}</h5>
              <ServiceGrid>
                {clickedStation.services.map(service => (
                  <Icon key={service.id}>
                    <img src={service.icon_select} alt={service.slug} />
                    <span>{service.name}</span>
                  </Icon>
                ))}
              </ServiceGrid>
            </div>
          )}
        </StationDrawer>
      </Aside>
    </MapWrap>
  )
}

export default MapPage

export const mapQuery = graphql`
  query mapQuery($locale: String!) {
    allStation(filter: { locale: { eq: $locale } }) {
      nodes {
        slug
        id
        name
        lat
        lng
        street
        zip
        phone_1
        phone_2
        tags
        city {
          name
        }
        county
        services {
          name
          slug
          icon_select
        }
        schedule {
          summer {
            day
            hours {
              from
              to
            }
          }
          winter {
            day
            hours {
              from
              to
            }
          }
        }
      }
    }
    allService(filter: { locale: { eq: $locale } }) {
      nodes {
        id
        slug
        name
        icon
        icon_select
      }
    }
  }
`
